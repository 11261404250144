@use "../../../styles/variables";
@use "../../../styles/textStyles";
@use "../../../styles/mediaQueries";

.footer {
  @include textStyles.footer;

  width: 100%;
  background-color: variables.$white100;
  border-top: 1px solid variables.$white107;

  & > div {
    box-sizing: border-box;
    padding: variables.$base-factor * 9;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include mediaQueries.md(max-width) {
      flex-direction: column;
      justify-content: center;
      gap: variables.$base-factor * 5;
    }

    & > div {
      flex: 1;
      display: flex;

      &:first-child {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include mediaQueries.md(max-width) {
          justify-content: center;
          align-items: center;
        }

        svg {
          height: variables.$base-factor * 5;
          width: auto;
          margin-bottom: variables.$base-factor * 4;
        }

        .footerDescription {
          flex: 1;
        }
      }

      &:last-child {
        align-items: flex-start;
        justify-content: center;
        gap: variables.$base-factor * 20;

        @include mediaQueries.md(max-width) {
          gap: variables.$base-factor * 10;
        }

        @include mediaQueries.sm(max-width) {
          gap: variables.$base-factor * 5;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: variables.$base-factor * 4;

          a {
            transition: color 0.55s ease-out;

            &:hover {
              color: variables.$purple100;
            }
          }

          h6 {
            margin-bottom: variables.$base-factor * 4;
          }
        }
      }
    }
  }
}
