/* stylelint-disable selector-class-pattern */
@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

// This mixin can be used to apply specific colors to a set of icons the right way.
@mixin colorIcons($color, $secondary-color: variables.$white90) {
  color: $color;

  &:global {
    &.arrowdown {
      stroke: $color;
    }

    &.arrowupright * {
      stroke: $color;
    }

    &.address {
      fill: $color;
      stroke: unset;
    }

    &.polygon {
      fill: $color !important;
    }

    &.optimism {
      path {
        fill: $color !important;
      }
    }

    &.serveroff {
      path {
        stroke: $color !important;
      }
    }

    &.bnb {
      path {
        fill: $color;
      }
    }

    &.cake > path {
      stroke: $color;
    }

    &.globe > path {
      stroke: $color;
    }

    &.globe2 {
      stroke: $color;
    }

    &.copy {
      stroke: $color;
    }

    &.youtube {
      path:first-child {
        stroke: $color;
        fill: $color;
      }

      path:last-child {
        stroke-width: 1px;
        stroke: $secondary-color;
        fill: $secondary-color;
      }
    }

    &.twitter {
      path {
        stroke: $color;
        fill: $secondary-color;
      }
    }

    &.star {
      path {
        stroke: $secondary-color;
        fill: $color;
      }
    }

    &.link {
      fill: transparent;

      path:first-child {
        stroke: $color;
      }

      path:last-child {
        stroke: $color;
      }
    }

    &.telegram {
      path:first-child {
        fill: $color;
      }

      path:last-child {
        fill: variables.$white90;
      }
    }

    &.braces {
      path {
        stroke: $color;
      }
    }
  }
}

// This mixin can be used to apply default colors to a set of chain icons the right way.
@mixin autoColorChainIcon {
  &:global {
    &.ethereum {
      path {
        fill: variables.$ethereum1;
      }
    }

    &.polygon {
      path {
        fill: variables.$polygon1;
      }
    }

    &.polygonpurple {
      path {
        fill: variables.$polygon1;
      }
    }

    &.bnb {
      path {
        fill: variables.$bnb1 !important;
      }
    }

    &.optimism {
      path {
        fill: variables.$optimism1 !important;
      }
    }

    &.avalanche {
      path:first-child {
        fill: variables.$white90;
      }

      path:last-child {
        fill: variables.$avalanche1;
      }
    }
  }
}

// This mixin can be used to apply default strokes to a set of icons the right way.
@mixin autoStrokeWidthIcons {
  stroke-width: 2px;

  path {
    stroke-width: 2px;
  }

  &:global {
    &.token {
      stroke-width: 1px;

      path {
        stroke-width: 1px;
      }
    }

    &.feather {
      path {
        stroke-width: 1.5px;
      }
    }

    &.info {
      path {
        stroke-width: 1.2px;
      }
    }

    &.lock {
      rect,
      path {
        stroke-width: 1.5px;
      }
    }

    &.bookopen {
      stroke-width: 0.9px;

      path {
        stroke-width: 0.9px;
      }
    }

    &.alertcircle {
      path:last-child {
        stroke-width: 2px;
      }
    }

    &.star {
      path {
        stroke-width: 1px;
      }
    }

    &.dollarsign {
      stroke-width: 2px;

      path {
        stroke-width: 2px;
      }
    }
  }
}

// This mixin can be used to apply specific strokes to a set of icons the right way.
@mixin strokeWidthIcons($stroke-width) {
  stroke-width: $stroke-width;

  path,
  rect {
    stroke-width: $stroke-width;
  }
}
