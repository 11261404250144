$mq-sm: 640px;
$mq-md: 768px;
$mq-lg: 1024px;
$mq-xl: 1280px;
$mq-xxl: 1536px;

@mixin sm($conditionType) {
  @media ($conditionType: $mq-sm) {
    @content;
  }
}

@mixin md($conditionType) {
  @media ($conditionType: $mq-md) {
    @content;
  }
}

@mixin lg($conditionType) {
  @media ($conditionType: $mq-lg) {
    @content;
  }
}

@mixin xl($conditionType) {
  @media ($conditionType: $mq-xl) {
    @content;
  }
}

@mixin xxl($conditionType) {
  @media ($conditionType: $mq-xxl) {
    @content;
  }
}

@mixin custom($conditionType, $breakpoint) {
  @media ($conditionType: $breakpoint) {
    @content;
  }
}
