@use "_textStyles";
@use "_variables";
@use "_mediaQueries";
@use "_utils";
@use "_specifics";

html {
  margin: 0;
  width: 100vw;
}

body {
  @include textStyles.body;

  background-color: variables.$white95;
  color: variables.$black100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;

  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  text-rendering: optimizelegibility;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  @include textStyles.headline1;

  margin: 0;
}

h2 {
  @include textStyles.headline2;

  margin: 0;
}

h3 {
  @include textStyles.headline3;

  margin: 0;
}

h4 {
  @include textStyles.headline4;

  margin: 0;
}

h5 {
  @include textStyles.headline5;

  margin: 0;
}

label {
  @include utils.allUnset;
  @include textStyles.inputLabel;

  color: variables.$black70;
  display: inline-flex;
  align-items: center;
  margin-bottom: variables.$base-factor;

  svg {
    margin-left: variables.$base-factor * 1;
    stroke-width: 2px;
    width: 15px;
  }
}

@keyframes pageAppear {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.page {
  animation-name: pageAppear;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  min-height: calc(100vh - variables.$footer-height);
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  padding-top: variables.$nav-bar-height;
  padding-bottom: 80px;

  &-fill {
    min-width: 100%;
    max-width: 100%;
  }

  &-header {
    background-color: variables.$purple100;
    border-radius: variables.$border-radius2;
    width: 100%;
    color: variables.$white90;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: variables.$base-factor * 4;
  }

  &-gutter-top {
    padding-top: variables.$nav-bar-height + variables.$base-factor * 10;
  }

  &-no-gutter-bottom {
    padding-bottom: 0 !important;
  }

  &-profile {
    $profile-picture-size: 119px;

    gap: variables.$base-factor * 10;
    min-width: 100%;
    max-width: 100%;

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: variables.$base-factor * 10;

      & > section {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: variables.$base-factor * 3;
        width: 100%;
      }
    }
  }
}

.max-content-width {
  margin: 0 auto;
  max-width: variables.$max-content-width;
  box-sizing: border-box;

  @media (max-width: variables.$max-content-width + variables.$base-factor * 16) {
    max-width: unset;
    padding-left: variables.$base-factor * 8;
    padding-right: variables.$base-factor * 8;
  }
}

a {
  @include utils.allUnset;

  cursor: pointer;
}

@keyframes enterModal {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes leaveModal {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

.link {
  @include utils.allUnset;
  @include textStyles.label;

  display: flex;
  align-items: center;
  gap: variables.$base-factor * 2;
  cursor: pointer;
  transition: color 0.1s ease-in-out;

  svg {
    width: variables.$base-factor * 3;
    aspect-ratio: 1/1;
    transition: color 0.1s linear;
  }

  &:hover {
    color: variables.$purple100;

    svg {
      @include specifics.colorIcons(variables.$purple100, variables.$white101);
    }
  }
}

// External libraries
html,
#privy-modal-content,
#privy-modal-content * {
  font-family: var(--manrope-font);
}

#privy-dialog-backdrop {
  background-color: variables.$black70;
  opacity: 0.8 !important;
}

#privy-modal-content {
  border-radius: variables.$border-radius4;
  border: 5px solid variables.$white101;

  input {
    @include textStyles.body;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
  }
}