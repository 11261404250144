@use "../../../styles/variables";
@use "../../../styles/textStyles";
@use "../../../styles/specifics";
@use "../../../styles/utils";
@use "../../../styles/mediaQueries";

$button-height: variables.$base-factor * 9;
$button-padding: variables.$base-factor * 8;
$button-no-icon-padding: variables.$base-factor * 12;
$button-only-icon-padding: variables.$base-factor * 3;

.tbButton {
  @include utils.allUnset;
  @include textStyles.button;

  align-items: center;
  border-radius: variables.$border-radius2;
  cursor: pointer;
  display: inline-flex;
  min-height: $button-height;
  max-height: $button-height;
  justify-content: center;
  line-height: 1;
  padding: 0 $button-padding;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  transition:
    background 0.15s ease-out,
    transform 0.15s ease-out,
    opacity 0.15s ease-out;

  & > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.15s ease-out;
    white-space: nowrap;
  }

  &NoIcon {
    padding: 0 $button-no-icon-padding;
  }

  &IconRight {
    svg {
      margin-right: 0;
    }
  }

  svg {
    @include specifics.autoStrokeWidthIcons;

    margin-right: variables.$base-factor * 2;
    width: variables.$base-factor * 4;
  }

  @include mediaQueries.md(min-width) {
    &:not(.tbButtonDisabled, .tbButtonFill):hover {
      transform: scaleX(110%);

      & > div {
        transform: scaleX(88%);
      }
    }
  }

  &Active {
    transform: scaleX(110%);

    & > div {
      transform: scaleX(88%);
    }

    &.tbButtonOutline {
      border-color: variables.$purple100;
      color: variables.$purple100;

      svg {
        stroke: variables.$purple100;
      }
    }
  }
}

.tbButtonOnlyIcon {
  padding: 0 $button-only-icon-padding;

  svg {
    margin: 0;
  }
}

.tbButtonPrimary {
  background-color: variables.$purple100;
  color: variables.$white90;

  svg {
    @include specifics.colorIcons(variables.$white90, variables.$purple110);

    transition: color 0.15s ease-out;

    * {
      transition:
        fill 0.15s ease-out,
        stroke 0.15s ease-out;
    }
  }

  &:hover {
    background-color: variables.$purple110;
  }
}

.tbButtonSecondary {
  background-color: variables.$black90;
  color: variables.$white90;

  svg {
    @include specifics.colorIcons(variables.$white90, variables.$black90);

    transition: color 0.15s ease-out;

    * {
      transition:
        fill 0.15s ease-out,
        stroke 0.15s ease-out;
    }
  }

  &:hover {
    background-color: variables.$black100;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$black100);
    }
  }
}

.tbButtonOutline {
  border: 1px solid variables.$black100;
  color: variables.$black100;
  backdrop-filter: blur(2px);
  transition:
    transform 0.15s ease-out,
    color 0.15s ease-out,
    border 0.15s ease-out;

  svg {
    @include specifics.colorIcons(variables.$black100, transparent);

    transition:
      fill 0.15s ease-out,
      stroke 0.15s ease-out,
      color 0.15s ease-out;

    path {
      transition:
        fill 0.15s ease-out,
        stroke 0.15s ease-out,
        color 0.15s ease-out;
    }
  }

  &:not(.tbButtonDisabled, .tbButtonNoColorSwitch):hover {
    border-color: variables.$purple100;
    color: variables.$purple100;

    svg {
      @include specifics.colorIcons(variables.$purple100);
    }
  }
}

.tbButtonFill {
  width: 100%;

  &:not(.tbButtonDisabled):hover {
    & > div {
      transform: scale(90%);
    }
  }
}

.tbButtonDisabled {
  cursor: not-allowed;
  opacity: 0.7;
}
