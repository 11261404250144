@use "../../../styles/variables";
@use "../../../styles/utils";
@use "../../../styles/mediaQueries";

.navbar {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100vw;
  border-bottom: 1px solid variables.$white107;
  background-color: variables.$white100;
  height: variables.$nav-bar-height;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

.navbarLogo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > svg {
    width: auto;
    fill: variables.$black100;
  }

  &Desktop {
    @include mediaQueries.md(max-width) {
      display: none;
    }

    height: variables.$base-factor * 5;

    [class$="logo-mark"] {
      path {
        fill: variables.$purple100;
      }
    }
  }

  &Mobile {
    height: variables.$base-factor * 10;

    @include mediaQueries.md(min-width) {
      display: none;
    }
  }
}
