// Colors
// Main
$purple130: #3b0f80;
$purple120: #4e13aa;
$purple110: #6218d4;
$purple100: #751dff;
$purple90: #8c43ff;
$purple80: #a368ff;
$purple70: #ba8eff;
$purple60: #d1b4ff;
$purple50: #e3d2ff;

// Black
$black100: #0f0e19;
$black90: #26252f;
$black80: #3e3d46;
$black70: #55545c;
$black60: #6d6c73;
$black50: #848389;
$black40: #9b9a9f;

// White
$white127: #b3b2b6;
$white117: #cac9cc;
$white107: #e2e1e3;
$white104: #e8e7e9;
$white102: #edecee;
$white101: #f2f2f3;
$white100: #f9f8f9;
$white95: #fcfcfc;
$white90: #fff;

// Semantic
$valid: #94cc71;
$error: #e84142;
$warning: #ffda35;
$warning2: #ffae35;
$progress: #61a6f0;

// Specific colors
$metamask1: #f6851b;
$walletconnect1: #3396ff;
$ledger1: #000;
$ethereum1: #121212;
$coinbase1: #0052ff;
$polygon1: #8246e4;
$avalanche1: #e84142;
$arbitrum1: #9abcd8;
$arbitrum2: #2c374b;
$arbitrum3: #24a1ef;
$optimism1: #ff0420;
$optimism2: #ffeaea;
$fantom1: #13b5ec;
$zksync1: #8c8dfc;
$zksync2: #4e529a;
$zksync3: #f1edff;
$bnb1: #f0b908;
$twitter1: #1da1f2;
$discord1: #7289da;
$telegram1: #08c;

// Sizing & Spacing
$base-factor: 4px;
$max-content-width: 1200px;
$nav-bar-height: 60px;
$footer-height: 238px;
$min-input-width: 170px;
$input-height: $base-factor * 9;

// Radius
$border-radius1: $base-factor * 1;
$border-radius2: $base-factor * 2;
$border-radius3: $base-factor * 3;
$border-radius4: $base-factor * 5;

// Shadows
$box-shadow1: rgba($white107, 0.5) 0 1px 2px 0,
  rgba($white107, 0.3) 0 1px 3px 1px;
$box-shadow2: 0 4px 4px rgba(0, 0, 0, 25%);
$box-shadow3: rgba($black80, 0.5) 0 1px 2px 0,
  rgba($black80, 0.3) 0 1px 3px 1px;
