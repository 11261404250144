@use "_mediaQueries";

@mixin headline1 {
  font-weight: 600;
  font-size: 58px;

  @include mediaQueries.xl(max-width) {
    font-size: 45px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 31px;
  }
}

@mixin headline2 {
  font-weight: 500;
  font-size: 48px;

  @include mediaQueries.sm(max-width) {
    font-size: 27px;
  }
}

@mixin headline3 {
  font-weight: 500;
  font-size: 34px;

  @include mediaQueries.sm(max-width) {
    font-size: 24px;
  }
}

@mixin headline4 {
  font-weight: 500;
  font-size: 28px;

  @include mediaQueries.xl(max-width) {
    font-size: 24px;
  }

  @include mediaQueries.lg(max-width) {
    font-size: 22px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 18px;
  }
}

@mixin headline5 {
  font-weight: 600;
  font-size: 18px;

  @include mediaQueries.sm(max-width) {
    font-size: 15px;
  }
}

@mixin body {
  font-weight: 500;
  font-size: 14px;
}

@mixin tag {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

@mixin label {
  font-weight: 500;
  font-size: 12px;
}

@mixin inputLabel {
  font-weight: 700;
  font-size: 12px;
}

@mixin navigation {
  font-weight: 500;
  font-size: 15px;
}

@mixin footer {
  font-weight: 500;
  font-size: 14px;

  @include mediaQueries.sm(max-width) {
    font-size: 12px;
  }
}

@mixin button {
  font-size: 14px;
  font-weight: 600;

  @include mediaQueries.sm(max-width) {
    font-size: 14px;
  }
}
