@use "../../../styles/variables";

$loading-global-size: variables.$base-factor * 10;

.tbLoadingGlobal {
  height: $loading-global-size;
  stroke: variables.$black50;
  width: $loading-global-size;
}

.tbLoadingButton {
  color: inherit;
}
