.tbIcon {
  stroke-width: 1.5px;
  width: 20px;

  path {
    stroke-width: 1.5px;
  }

  &:global(.metamask) {
    stroke-width: 0.5px;

    path {
      stroke-width: 0.1px;
    }
  }
}
