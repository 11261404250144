@use "_specifics";

@mixin allUnset {
  all: unset;
}

@mixin overrideButtonHover($color: currentcolor, $icon-color: $color, $icon-secondary-color: currentcolor) {
  &:hover {
    border-color: $color !important;
    color: $color !important;

    svg {
      @include specifics.colorIcons($icon-color !important, $icon-secondary-color !important);
    }
  }
}

@mixin nameGridItems($prefix, $number-of-items) {
  $i: 1;

  @for $i from 1 through $number-of-items {
    & > *:nth-child(#{$i}) {
      grid-area: #{$prefix}-#{$i};
    }
  }
}
