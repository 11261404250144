@use "_variables";
@use "_textStyles";
@use "_utils";
@use "_specifics";
@use "_mediaQueries";

.tbAuthHandlerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: variables.$base-factor * 2;

  .tbAuthHandlerBubbleError {
    width: 100%;
  }

  & > * {
    box-sizing: border-box;
  }

  .tbAuthHandlerConnectedButtons {
    display: flex;
    align-items: center;
    justify-content: center;

    *:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    *:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
